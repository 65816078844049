 body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
    background-color: #f4f4f4;
    height: 100%;
}
.main-logo{
    max-width: 400px;
}
.main-logo-top{
    margin: 1rem auto;
}

.formRadioLabel{
    font-size: 1rem !important;
    font-family: Arial !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    position: inherit !important;
    transform: none !important;
    display: inline !important;
    align-items: center !important;
    margin: 0 !important;
    margin-right: 15px !important;
    color: black !important;
    justify-content: center !important;
    margin-block-start: 0px !important;
    margin-block-end: 0px !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
}
.Mui-error{ 
    color: #f44336 !important;
}*/
/* .MuiFormControl-root {
    align-items: center;
}
.MuiGrid-align-items-xs-center {
    margin: 0 auto;
} */

.datePicker,
.datePicker > div, 
.datePicker > div > div {
    width: 100%;
}

.datePicker {
    margin-bottom: 25px;
}

.datePicker .MuiFormControl-root,
.datePicker .MuiFormControl-root > div {
    width: 100%;
}